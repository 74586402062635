import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";

interface Props {
  targetUrl: string;

  tokenData: {
    csrfToken: string;
    token: string;
  } | null;
}

const useLayoutEffectSSR =
  typeof window === "undefined" ? useEffect : useLayoutEffect;

const TokenSubmitForm = forwardRef(
  ({ targetUrl, tokenData }: Props, ref: ForwardedRef<HTMLFormElement>) => {
    return (
      <form ref={ref} action={targetUrl} className="hidden" method="post">
        <input
          name="csrfToken"
          type="hidden"
          value={tokenData?.csrfToken || ""}
          readOnly
        />
        <input name="callbackUrl" type="hidden" value="/" />
        <input
          name="token"
          type="hidden"
          value={tokenData?.token || ""}
          readOnly
        />
      </form>
    );
  }
);

TokenSubmitForm.displayName = "TokenSubmitForm";

const useTokenSubmitForm = (
  targetUrl: string,
  tokenData: Props["tokenData"]
) => {
  const formRef = useRef<HTMLFormElement>(null);
  const formNode = (
    <TokenSubmitForm
      ref={formRef}
      targetUrl={targetUrl}
      tokenData={tokenData}
    />
  );

  useLayoutEffectSSR(() => {
    if (tokenData && formRef.current) {
      formRef.current.submit();
    }
  }, [tokenData]);

  return formNode;
};

export default useTokenSubmitForm;
