import { Field, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as Yup from "yup";

import { MFAFormValues } from "../../models/login";
import { getYupValidationSchema } from "../../utils/validation";
import { Button } from "../base/form-controls/Button";
import { FloatingInput as Input } from "../base/form-controls/FloatingInput";
import { Subtitle } from "../base/typography";

import { isError } from "./LoginForm";

const validationSchema = getYupValidationSchema({
  code: Yup.string().required("Required field"),
});

const mfaInitial = {
  code: "",
};

interface Props {
  targetInfo?: string;

  initial?: MFAFormValues;

  onCancel: () => void;

  onSubmit: FormikConfig<MFAFormValues>["onSubmit"];
}

export default function MFAForm({
  targetInfo,
  initial,
  onCancel,
  onSubmit,
}: Props) {
  return (
    <Formik
      initialValues={initial || mfaInitial}
      validateOnBlur={true}
      validateOnChange={true}
      validateOnMount={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<MFAFormValues>) => (
        <Form
          className="flex-auto flex flex-col justify-between space-y-3"
          onSubmit={(e) => {
            props.handleSubmit(e);
          }}
        >
          <Subtitle>
            An SMS was sent to {targetInfo || "your phone"}. Please enter the
            code you received in this box.
          </Subtitle>

          <Field
            as={Input}
            errorMessage={props.errors.code}
            hint=" "
            isError={isError(props, "code")}
            label="MFA Code"
            name="code"
            type="text"
          />

          <Button
            className="justify-center"
            disabled={props.isSubmitting}
            type="submit"
          >
            Verify
          </Button>

          <div className="text-center">
            <a
              className="relative text-center cursor-pointer text-brand-600 hover:text-brand-700 font-medium"
              href=""
              onClick={(e) => {
                if (props.isSubmitting) {
                  e.preventDefault();
                  return;
                }

                onCancel();
                e.preventDefault();
              }}
            >
              Cancel
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
}
