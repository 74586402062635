import { FC, Fragment, ReactElement, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

export interface ModalProps {
  /**
   * Optional Modal title
   */
  title?: string;
  /**
   * Optional Modal body
   */
  body?: string | ReactElement;
  /**
   * Modal's state of visibility
   */
  displayModal: boolean;
  /**
   * Click handler that controls the modal's state of visibility
   */
  setDisplayModal: (display: boolean) => void;
  /**
   * React component to render in the modal
   */
  childComponent?: React.ReactNode;

  /**
   * Optional indicator if error icon is shown on the left side of Modal title
   */
  isError?: boolean;

  /**
   * Optional property to disable icons
   */
  IsIconVisible?: boolean;


}

export const Modal: FC<ModalProps> = (props: ModalProps) => {
  function closeModal() {
    props.setDisplayModal(false);
  }

  return (
    <Transition.Root as={Fragment} show={props.displayModal} appear>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={closeModal}
      >
        <div className=" justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-0" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            aria-hidden="true"
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 z-10">
              <div>
                <div className="ml-auto flex justify-end">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                      type="button"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Dismiss</span>
                      <XIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex text-lg font-medium leading-6 text-gray-900"
                >
                  {props.IsIconVisible !== false && (props.isError ? (
                    <ExclamationIcon
                      aria-hidden="true"
                      className="h-6 w-6 mr-2 text-red-600"
                    />
                  ) : (
                    <CheckIcon
                      aria-hidden="true"
                      className="h-6 w-6 mr-2 text-green-600"
                    />
                  ))}
                  {props.title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{props.body}</p>
                </div>
                {props.childComponent && (
                  <div className="mt-3 text-center sm:mt-5">
                    {props.childComponent}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const useDisclosure = (initialOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => (isOpen ? close() : open());

  return { isOpen, open, close, toggle };
};
