import { useCallback, useEffect, useMemo, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { useRouter } from "next/router";
import { getCsrfToken, getProviders, useSession } from "next-auth/react";

import "../amplify";

import { Modal } from "../components/base/overlay/Modal";
import ColumnInfo from "../components/home/BrandInfo";
import LeftCol from "../components/home/LeftCol";
import RightCol from "../components/home/RightCol";
import LoginForm from "../components/login/LoginForm";
import MFAForm from "../components/login/MFAForm";
import useTokenSubmitForm from "../components/login/TokenSubmitForm";
import { UpdateUserInfo } from "../components/users/UpdateUserInfo";
import { LoginFormValues, MFAFormValues } from "../models/login";
import { CustomErrorMessage, ROLE } from "../utils/constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getServerSideProps(context) {
  const providers = await getProviders();

  return {
    props: { providers },
  };
}

export default function Login({ providers }) {
  const router = useRouter();
  const [loginChallenge, setChallenge] = useState("");
  const [challengeUser, setChallengeUser] = useState<any | null>(null);
  const [tokenData, setTokenData] = useState<{
    token: string;
    csrfToken: string;
  } | null>(null);

  const session = useSession();

  const onReceivedToken = useCallback(
    async (jwtToken: string) => {
      // Remember their device
      await Auth.rememberDevice();

      // Get csrf token to make a post
      const csrfToken = await getCsrfToken();

      // Set token data. This creates a hidden form which is auto-submitted
      setTokenData({
        csrfToken: csrfToken || "",
        token: jwtToken,
      });
    },
    [setTokenData]
  );

  const onLoginSubmit = useCallback(
    async (values: LoginFormValues, { setErrors, setSubmitting }) => {
      setSubmitting(true);

      try {
        const result = await Auth.signIn(values.email, values.password);

        if (result.challengeName) {
          // Got a login challenge - handle it
          switch (result.challengeName) {
            case "SMS_MFA":
              setChallengeUser(result);
              setChallenge(result.challengeName);
              break;

            case "NEW_PASSWORD_REQUIRED":
              setChallengeUser({
                ...values,
              });
              setChallenge("PROVIDER_REGISTRATION");
              break;

            default:
              setErrors({
                email: `Got unsupported login challenge - ${result.challengeName}`,
              });
              break;
          }

          setSubmitting(false);
          return;
        }
        // Send token to next-auth
        await onReceivedToken(result.signInUserSession.accessToken.jwtToken);
      } catch (error: any) {
        switch (error.code) {
          case "NotAuthorizedException":
            setErrors({
              email: error.message,
            });
            break;

          default:
            setErrors({
              email: `${error}`,
            });
        }

        setSubmitting(false);
      }
    },
    [setChallenge, onReceivedToken]
  );

  const onMFASubmit = useCallback(
    async (values: MFAFormValues, { setErrors, setSubmitting }) => {
      setSubmitting(true);

      try {
        const result = await Auth.confirmSignIn(
          challengeUser,
          values.code,
          loginChallenge as "SMS_MFA"
        );

        if (result.signInUserSession?.accessToken?.jwtToken) {
          // Send token to next-auth
          onReceivedToken(result.signInUserSession.accessToken.jwtToken);
        } else {
          // Nested challenges are not supported!
          if (result.challengeName) {
            setErrors({
              email: `Got unsupported login challenge - ${result.challengeName}`,
            });
            setSubmitting(false);

            return;
          }

          throw new Error("Something has gone wrong, please try again.");
        }
      } catch (error: any) {
        switch (error.code) {
          case "CodeMismatchException":
            setErrors({
              code: error.message,
            });
            break;

          case "NotAuthorizedException":
            if (error.message.includes("expired")) {
              setErrors({
                code: "The MFA code has expired. Please try again!",
              });
            } else {
              setErrors({
                code: `${error}`,
              });
            }
            break;

          default:
            setErrors({
              code: `${error}`,
            });
        }
        setSubmitting(false);
      }
    },
    [onReceivedToken, loginChallenge, challengeUser]
  );

  const onCancelMFA = useCallback(() => {
    setChallengeUser(null);
    setChallenge("");
  }, []);

  const onCancelProviderChallenge = useCallback(() => {
    setChallengeUser(null);
    setChallenge("");
  }, []);

  const initialProviderState = useMemo(
    () => ({
      username: challengeUser?.email ?? "",
      password: challengeUser?.password ?? "",
    }),
    [challengeUser]
  );

  const tokenSubmitForm = useTokenSubmitForm(
    providers.credentials.callbackUrl,
    tokenData
  );

  // TODO: copy index.tsx logic for redirecting
  useEffect(() => {
    if (session.status === "authenticated") {
      if (session.data.role === ROLE.SuperAdmin) {
        router.push("/super-admin");
        return;
      }
      router.push("/company");
    }
  }, [router, session]);

  if (session.status === "authenticated") {
    return null;
  }

  return (
    <div className="flex h-screen min-h-screen">
      <LeftCol info={<ColumnInfo />}>
        {loginChallenge === "SMS_MFA" ? (
          <MFAForm
            targetInfo={
              challengeUser?.challengeParam?.CODE_DELIVERY_DESTINATION
            }
            onCancel={onCancelMFA}
            onSubmit={onMFASubmit}
          />
        ) : null}
        {loginChallenge === "" ? (
          <LoginForm
            globalError={
              Object.values(CustomErrorMessage).includes(
                router.query?.error as CustomErrorMessage
              )
                ? (router.query.error as string)
                : router.query.error
                ? "Unexpected error, please contact support@profileatheticmanagment.com"
                : ""
            }
            onSubmit={onLoginSubmit}
          />
        ) : null}

        <Modal
          childComponent={
            <UpdateUserInfo initialState={initialProviderState} />
          }
          displayModal={loginChallenge === "PROVIDER_REGISTRATION"}
          setDisplayModal={onCancelProviderChallenge}
        />

        {tokenSubmitForm}
      </LeftCol>
      <RightCol />
    </div>
  );
}
