import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import classnames from "classnames";

export interface SuccessMessageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean;
  header?: string;
  message?: string;
  footerElements?: JSX.Element;
}

export default function SuccessMessage({
  show = true,
  header = "",
  message = "",
  footerElements,
  className,
}: SuccessMessageProps) {
  return (
    <Transition.Root
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={show}
    >
      <div className={classnames(className, "rounded-md bg-emerald-50 p-4")}>
        <div className="flex">
          <div className="flex-shrink-0 self-center">
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-emerald-400"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-emerald-800">{header}</h3>
            <div className="mt-2 text-sm text-emerald-700">
              <p>{message}</p>
            </div>
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">{footerElements}</div>
            </div>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
}
