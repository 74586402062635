import { useState } from "react";
import { Field, Form, Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";

import {
  UpdateProviderInfoValues as UpdateUserInfoValues,
  userRegisterProvider as useRegisterUser,
} from "../../data/providers";
import { validationSchemas } from "../../utils/validation";
import FailMessage from "../base/feedback/FailMessage";
import SuccessMessage from "../base/feedback/SuccessMessage";
import { Button } from "../base/form-controls/Button";
import { FloatingInput } from "../base/form-controls/FloatingInput";

import { FormField } from "./types";

interface UpdateUserInfoProps {
  initialState?: Partial<UpdateUserInfoValues>;
}

export const UpdateUserInfo = ({ initialState }: UpdateUserInfoProps) => {
  const [submitted, setSubmitted] = useState(false);

  const fieldMap: { [key: string]: FormField } = {
    username: { name: "username", label: "User Email", type: "email" },
    password: {
      name: "password",
      label: "Temporary Password",
      type: "password",
    },
    newPassword: {
      name: "newPassword",
      label: "New Password",
      type: "password",
    },
    phoneNumber: { name: "phoneNumber", label: "Phone Number", type: "tel" },
    firstName: { name: "firstName", label: "First Name", type: "text" },
    lastName: { name: "lastName", label: "Last Name", type: "text" },
    address: { name: "address", label: "Address", type: "text" },
    licenseNumber: {
      name: "licenseNumber",
      label: "License Number",
      type: "text",
    },
    trainingCertificate: {
      name: "trainingCertificate",
      label: "Training Certificate",
      type: "text",
    },
  };

  const keys = Object.keys(fieldMap);
  const defaultState: UpdateUserInfoValues = {
    username: "",
    password: "",
    newPassword: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    address: "",
    licenseNumber: "",
    trainingCertificate: "",
    ...initialState,
  };

  const { data, error, loading, call } = useRegisterUser();

  const CustomFloatingInput = (
    name: string,
    errors: FormikErrors<UpdateUserInfoValues>,
    touched: FormikTouched<UpdateUserInfoValues>
  ) => {
    return (
      <Field
        key={name}
        as={FloatingInput}
        className="my-4"
        errorMessage={errors[name]}
        isError={errors[name] && touched[name]}
        label={fieldMap[name].label}
        name={name}
        type={fieldMap[name].type}
      />
    );
  };

  return (
    <div>
      <SuccessMessage
        message="New user account created."
        show={Boolean(data) && submitted}
      />
      <Formik
        initialValues={defaultState}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape(validationSchemas.providerSchema)}
        enableReinitialize
        onSubmit={async (values: UpdateUserInfoValues) => {
          await call({ body: values });
          setSubmitted(true);
        }}
      >
        {({ errors, touched, isSubmitting, setErrors }) => {
          return !data?.ok ? (
            <Form
              className="bg-white text-center rounded max-w-xs"
              onChange={() => {
                setErrors({});
                setSubmitted(false);
              }}
            >
              {error && submitted && (
                <FailMessage
                  message={error?.translatedMessage}
                  show={Boolean(error)}
                />
              )}
              {keys.map((k) => CustomFloatingInput(k, errors, touched))}

              <Button disabled={loading || isSubmitting} type="submit">
                Submit User Registration
              </Button>
            </Form>
          ) : null;
        }}
      </Formik>
    </div>
  );
};
