import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/solid";
import classnames from "classnames";

export interface FailMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  show?: boolean;
  header?: string;
  message?: string;
  footerElements?: JSX.Element;
}

export default function FailMessage({
  show = true,
  header = "",
  message = "",
  footerElements,
  className,
}: FailMessageProps) {
  return (
    <Transition.Root
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={show}
    >
      <div className={classnames(className, "rounded-md bg-red-50 p-4")}>
        <div className="flex">
          <div className="flex-shrink-0 self-center">
            <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{header}</h3>
            {message && (
              <div className="mt-2 text-sm text-red-700">
                <p>{message}</p>
              </div>
            )}
            {footerElements && (
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex text-sm text-red-800">
                  {footerElements}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition.Root>
  );
}
