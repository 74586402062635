import Amplify from "@aws-amplify/auth";

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.NEXT_PUBLIC_COGNITO_REGION,
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_SRP_AUTH",

    // Note: It is not possible to set oauth scopes here. The scope defaults to `aws.cognito.signin.user.admin`.
    //
    //  see here for an upstream issue - https://github.com/aws-amplify/amplify-ui/issues/237
  },
});

// You can get the current config object
// const currentConfig = Auth.configure();
