import { FormikProps } from "formik";
import * as Yup from "yup";

import { createProfileApiHook, useProfileApiData } from "../utils/apiHelpers";

import { Patient } from "./patients";
import { ProfileUnprocessableEntityError } from ".";

export interface InviteProvider {
  username: string;
}

export interface DeleteProvider {
  username: string;
}

export interface PromoteProviderToAdmin {
  username: string;
}

export interface Provider {
  username: string;
  sub?: string;
  address?: string;
  companyId?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  isProvider?: boolean;
  licenseNumber?: string;
  trainingCertificate?: string;
  companyName?: string;
  isAdmin?: boolean;
  label?: string;
  createdAt?: string;
  deletedAt?: string;
}

export interface ProviderWithFullAddress extends Provider {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export type ProviderEditForm = Omit<Provider, "sub" | "companyId" | "isAdmin" | "isProvider">;

export interface ProviderResponse {
  success: boolean;
  data: Provider;
}

export interface AllProvidersResponse {
  success: boolean;
  data: Provider[];
}

export interface ProviderPatientsResponse {
  success: boolean;
  data: Patient[];
}

export interface ProviderAvatarResponse {
  status: number;
  signedUrl: string;
}

export interface UpdateProviderInfoValues {
  username: string;
  password: string;
  newPassword: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  address: string;
  licenseNumber: string;
  trainingCertificate: string;
}

export interface ProviderErrorResponse {
  message?: string | object;
  error?: string | object | ProfileUnprocessableEntityError;
}

export interface ProviderSuccessResponse {
  message: string;
}

export interface UserPatientPayload {
  patientId: string;
  userId: string;
}

export interface UserPatientsPayload {
  userId: string;
}

export const UserValidationSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email format").required("Required"),
  address: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  licenseNumber: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  trainingCertificate: Yup.string().required("Required"),
});

export const isUserFormFieldError = <T extends Provider | ProviderEditForm>(
  field: FormikProps<T>,
  fieldName: keyof T
) => {
  if (field.touched[fieldName] && field.errors[fieldName]) {
    return true;
  }
  return false;
};

export const userRegisterProvider = createProfileApiHook<
  ProviderSuccessResponse,
  ProviderErrorResponse,
  UpdateProviderInfoValues
>("/registerProvider", "POST", {
  errorCallback: ({ status, response }) => {
    switch (status) {
      case 400:
        return "Temporary password is incorrect.";
      case 401:
        return "Currently unauthorized. Try logging in again.";
      case 422:
        response as ProfileUnprocessableEntityError;
        return "Email is incorrectly formatted.";
      default:
        return "An unknown error occurred. Try again later.";
    }
  },
});

export const useCreateProvider = createProfileApiHook<ProviderSuccessResponse, ProviderErrorResponse, InviteProvider>(
  "/adminCreateProvider",
  "POST",
  {
    errorCallback: ({ status }) => {
      switch (status) {
        case 400:
          return "Email is invalid.";
        case 401:
          return "Currently unauthorized to add a provider. Try logging in again.";
        case 409:
          return "Email already exists as a provider.";
        case 422:
          return "Email is incorrectly formatted.";
        default:
          return "An unknown error occurred. Try again later.";
      }
    },
  }
);

export const useDeleteProvider = createProfileApiHook<ProviderSuccessResponse, ProviderErrorResponse, DeleteProvider>(
  "/adminDeleteUser",
  "POST",
  {
    errorCallback: ({ status }) => {
      switch (status) {
        default:
          return "An unknown error occurred. Try again later.";
      }
    },
  }
);

export function useAllProviders(token?: string, includeDeleted = false, includeAdmins = false) {
  const queryParams = {
    ...(includeDeleted && { includeDeleted: "true" }),
    ...(includeAdmins && { includeAdmins: "true" }),
  };
  return useProfileApiData<AllProvidersResponse, ProviderErrorResponse>("/getProviders", {
    token,
    headers: { "Accept-version": 2 },
    ...(Object.keys(queryParams).length > 0 && { queryParams }),
  });
}

export function useProviderPatients(username: string, token?: string) {
  return useProfileApiData<ProviderPatientsResponse, ProviderErrorResponse>(`/userPatients/${username}`, {
    token,
  });
}

export function useProviderAvatar(username?: string, token?: string) {
  return useProfileApiData<ProviderAvatarResponse, ProviderErrorResponse>(`/users/avatar-url/${username}`, {
    token,
  });
}

export function useProviderData(providerId: string, token?: string, includeDeleted = false) {
  return useProfileApiData<ProviderResponse, ProviderErrorResponse>(`/getUser/${providerId}`, {
    token,
    ...(includeDeleted && { queryParams: { includeDeleted: "true" } }),
  });
}

export function useSharedPatients(username: string, token?: string) {
  return useProfileApiData<ProviderPatientsResponse, ProviderErrorResponse>(`/sharedPatients/${username}`, {
    token,
  });
}

export const useUpdateProvider = (id: string) =>
  createProfileApiHook<ProviderSuccessResponse, ProviderErrorResponse, Provider>(`/updateUser/${id}`, "POST")();

export const useRemoveUserPatient = createProfileApiHook<
  ProviderSuccessResponse,
  ProviderErrorResponse,
  UserPatientPayload
>("/userPatients", "DELETE");

export const useAssignAllPatientsToUser = createProfileApiHook<
  ProviderSuccessResponse,
  ProviderErrorResponse,
  UserPatientsPayload
>("/assignAllPatientsToUser", "PUT");

export const usePromoteProviderToAdmin = () =>
  createProfileApiHook<ProviderSuccessResponse, ProviderErrorResponse, PromoteProviderToAdmin>(
    "/promoteProviderToAdmin",
    "POST"
  )();
