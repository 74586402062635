import { Field, Form, Formik, FormikConfig, FormikProps } from "formik";

import { LoginFormValues } from "../../models/login";
import { getYupValidationSchema, loginSchema } from "../../utils/validation";
import { Button } from "../base/form-controls/Button";
import { FloatingInput as Input } from "../base/form-controls/FloatingInput";
import ForgotPassword from "../home/ForgotPassword";

const validationSchema = getYupValidationSchema(loginSchema);

interface LoginFormProps {
  initial?: LoginFormValues;
  globalError?: string;
  onSubmit: FormikConfig<LoginFormValues>["onSubmit"];
}

const loginInitial: LoginFormValues = {
  email: "",
  password: "",
};

export const isError = <T extends object>(
  field: FormikProps<T>,
  fieldName: keyof T
): boolean =>
  !!(field.touched[fieldName] && field.errors[fieldName]) ||
  !!(!field.touched[fieldName] && field.initialErrors[fieldName]);

const LoginForm = ({ initial, globalError, onSubmit }: LoginFormProps) => {
  return (
    <Formik
      initialErrors={
        globalError
          ? {
              email: globalError,
            }
          : undefined
      }
      initialValues={initial || loginInitial}
      validateOnBlur={true}
      validateOnChange={true}
      validateOnMount={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<LoginFormValues>) => (
        <Form
          action=""
          className="flex-auto flex flex-col justify-between space-y-3"
          method="post"
          onSubmit={props.handleSubmit}
        >
          <Field
            as={Input}
            errorMessage={props.errors.email}
            hint=" "
            isError={isError(props, "email")}
            label="Email"
            name="email"
            type="email"
          />
          <Field
            as={Input}
            errorMessage={props.errors.password}
            hint=" "
            isError={isError(props, "password")}
            label="Password"
            name="password"
            type="password"
          />
          <Button
            className="justify-center"
            disabled={props.isSubmitting}
            type="submit"
          >
            Login
          </Button>

          <ForgotPassword />
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
